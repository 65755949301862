import process from "process";
import testConstants from "./constants.test";
import prdConstants from "./constants.production";
import devConstants from "./constants.dev";
import genStorageKeyByAppType from '../utils/app/genStorageKeyByAppType';

const envConstantsMap = {
  production: prdConstants,
  test: testConstants,
  dev: devConstants,
};

// @ts-ignore
const envConstants = envConstantsMap[process.env.DEPLOY_ENV] || devConstants;

export const {
  SERVER_URL,
  DEPLOY_DIR,
  APP_URL,
  H5_STATIC_SERVER_URL,
  WX_APPID,
  WX_MP_APP_ID,
  SHOW_MAI_DIAN_LOG,
  ALLOW_MOBILE_LOGIN_PAGE,
  JW_APP_URL,
  JW_KUAIPK_APP_URL,
  JW_KUAIJK_APP_URL,
  JW_KUAIXK_APP_URL,
  JW_KUAICJ_APP_URL,
} = envConstants;

// 后端服务前缀
export const BASE_SYSTEM_USER = "/base-system-user";
export const SUPPORT_SERVICE = "/ttfs-support";
export const BASE_SCHOOL_COURSE = "/base-school-course";
export const BASE_SCHOOL_TEACHER = "/base-school-teacher";
export const BASE_SCHOOL = "/base-school";
export const SCHEDULING_ENGINE = "/schedule/engine/scheduleproject";
export const BASE_PARSE = "/base-parse";
export const BASE_SALES = "/base-sales";
export const BASE_USER_BEHAVIOR = "/base-user-behavior";
export const BASE_NCEE_CLASS_SCHEDULING = "/base-nceeclassscheduling"; // 新版课表
export const BASE_ARRANGE_EXAM_ROOM = "/base-arrangexamroom"; // 排监考
export const BASE_SCHEDULING = "/base-newscheduling"; // 新版排课
export const BASE_NEW_SCHEDULING = "/base-newscheduling"; // 新版排课

export const KUAIJZ_MP_APP_ID = "wxb31a32b804116817"; // 倍思家长微信小程序appid

// 前端localStorage相关Key
export const USER_TOKEN = genStorageKeyByAppType('user_token'); // token
export const WECOM_CORP_ID = genStorageKeyByAppType('wecom_corp_id'); // 企业id
export const USER_INFO = genStorageKeyByAppType('user_info'); // 用户信息（停用）
export const SCHOOL_INFO = genStorageKeyByAppType('school_info'); // 学校信息
export const USER_ID = genStorageKeyByAppType('user_id'); // 用户id
export const SELECT_SUBJECT_CURRENT_TASK = genStorageKeyByAppType('select_subject_current_task'); // 提示集合

export const VISITOR_FROM = genStorageKeyByAppType('visitor_from'); // 客户来源
export const VISITOR_REF = genStorageKeyByAppType('visitor_ref'); // 页面来源
