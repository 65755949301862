import { isJwInlandApp, isJwIntlApp, isPersonalApp } from "@/utils/app/env";

export default function getHeaderProduct() {
  if (isJwInlandApp) {
    return "KUAIJW"
  }
  if (isJwIntlApp) {
    return "KUAIGJ"
  }
  if (isPersonalApp) {
    return "KUAIXK"
  }

  return ""
}
