import { SchoolInfo } from "@/services/school";
import provinceList from "@province-city-china/province";
import cityList from "@province-city-china/city";
import areaList from "@province-city-china/area";
import { message } from "antd";

export const isObj = (data: any) => {
  return typeof data === "object" && data !== null && !Array.isArray(data);
};

export const clearUndefined = (data: Record<string, unknown>) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      Reflect.deleteProperty(data, key);
    }
  });
  return data;
};

// 使用文件流下载（企微不支持）
export function downloadFileStream(fileStream: Blob | string, filename: string, type?: string) {
  const blob = new Blob([fileStream], { type: type || "" });
  const a = document.createElement("a");
  a.download = filename;
  a.style.display = "none";
  a.href = URL.createObjectURL(blob);
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

// 使用url下载.
export function downloadFileUrl(url: string, target?: "_self" | "_blank") {
  const a = document.createElement("a");
  a.href = url;
  a.target = target || "_self";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function downloadResponseHandle(response: Response) {
  const blob = await response.blob();

  // json表示有异常
  if (blob.type === "application/json") {
    return JSON.parse(await blob.text());
  }

  const filenameEntryStr = response.headers.get("Content-Disposition")?.split(";")[1];
  const filename = decodeURIComponent(filenameEntryStr?.split("=")[1] || "");
  downloadFileStream(blob, filename.replace(/["']/g, ""));

  return {
    code: 200,
  };
}

export const numberToDay: Record<string | number, string> = {
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
  7: "星期日",
};

export const numberToDaySimple: Record<string | number, string> = {
  1: "一",
  2: "二",
  3: "三",
  4: "四",
  5: "五",
  6: "六",
  7: "日",
};

export const numberToGrade: Record<string | number, string> = {
  1: "一年级",
  2: "二年级",
  3: "三年级",
  4: "四年级",
  5: "五年级",
  6: "六年级",
  7: "七年级",
  8: "八年级",
  9: "九年级",
};

export const gradeToNumber: Record<string, number> = {
  一年级: 1,
  二年级: 2,
  三年级: 3,
  四年级: 4,
  五年级: 5,
  六年级: 6,
  七年级: 7,
  八年级: 8,
  九年级: 9,
};

// 获取表格行合并数据
export const getTableCellCollapsedData = (dataIndex: string, list: any[] = []) => {
  let timesCountMap = new Map();
  list.forEach((item) => {
    if (!timesCountMap.has(item[dataIndex])) {
      timesCountMap.set(item[dataIndex], 1);
    } else {
      timesCountMap.set(item[dataIndex], timesCountMap.get(item[dataIndex]) + 1);
    }
  });
  const data: any[] = [];
  [...timesCountMap].forEach((entry) => {
    const count = entry[1];
    for (let i = 0; i < count; i++) {
      if (i === 0) {
        data.push({
          rowSpan: count,
        });
      } else {
        data.push({
          rowSpan: 0,
        });
      }
    }
  });
  return data;
};

export const uniqArr = (arr: any[]) => {
  return [...new Set(arr)];
};

const chineseNumMap = new Map([
  ["一", 1],
  ["二", 2],
  ["三", 3],
  ["四", 4],
  ["五", 5],
  ["六", 6],
  ["七", 7],
  ["八", 8],
  ["九", 9],
]);

export const chineseNumToNum = (chineseNum: string) => {
  return chineseNumMap.get(chineseNum) || 0;
};

export const getSchoolLocationName = (school?: SchoolInfo) => {
  if (!school) {
    return "";
  }
  const provinceName = provinceList.find((item) => school.provinceCode === item.code)?.name;
  const cityName = cityList.find((item) => school.cityCode === item.code)?.name;
  const areaName = areaList.find((item) => school.areaCode === item.code)?.name;

  return `${provinceName || ""}${cityName || ""}${areaName || ""}`;
};

export const isBase64 = (str: string) => {
  if (str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (e) {
    return false;
  }
};

export const getIsWxBrowser = () => {
  const ua = navigator.userAgent.toLowerCase();
  // 微信浏览器并且非企业微信浏览器
  return ua.indexOf("micromessenger") !== -1 && ua.indexOf("wxwork") === -1;
};

export const ellipsisText = (str: string, maxLength: number) => {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + "...";
};

export const mobileReg = /^1[3-9]\d{9}$/;

export const wrapDownloadMessageLoading = (promiseFn?: () => Promise<any>) => {
  if (!promiseFn) {
    return promiseFn;
  }
  return async () => {
    let close;
    try {
      close = message.loading({ content: "正在下载文件..." });
      await promiseFn();
    } finally {
      close?.();
    }
  };
};


export function getScrollHeight(element: HTMLDivElement) {
  // 获取元素的内容高度
  const totalHeight = element.offsetHeight;
  // 获取元素的可视区域高度
  const viewportHeight = element.clientHeight;
  // 获取滚动条高度
  const scrollHeight = totalHeight - viewportHeight;
  return scrollHeight;
}

// 换行
export function lineFeedIfMax(str: string, max: number): string {
  const cList = str.split("\n");
  let last = cList.pop() || "";
  if (last.length > max) {
    last = last.slice(0, max) + "\n" + last.slice(max);
  }
  return cList.length ? cList.join("\n") + "\n" + last : last;
}

export const getBackUrl = () => {
  const backUrl = window.location.href.split('#')[1]

  return encodeURIComponent(backUrl)
}
